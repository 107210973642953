.apexcharts-pie .apexcharts-datalabels-group .apexcharts-datalabel-value{
  display: none !important;
}

/* Pie Chart Css */
div.pieChartGrid>div:first-child{
  min-height: 450px !important;
}

#apexchartswindowFloorChart, #apexchartswindowFloorChart>svg{
  height: 450px !important;
}